<template>
   <div
      style="padding-left: 22px; padding-right: 22px; box-sizing: border-box;"
      class="app-container grey lighten-5 v-sheet v-sheet--shaped mt-4" light>
      <v-card
         v-show="!systemDesign"
         class="app-container v-sheet v-sheet--shaped grey lighten-5 mt-4 mb-8" light>
         <v-row space-around>
            <v-col class="d-inline">
               <v-card-title>Models</v-card-title>

               <SiteSelect :selectedSite.sync='selectedSite'/>

            </v-col>
            <v-col class="d-flex justify-end">
               <v-btn color="primary" class="mr-4" @click="createItem">
                  <v-icon left>
                     mdi-plus-circle
                  </v-icon>
                  Create new Model
               </v-btn>
            </v-col>
         </v-row>
      </v-card>

      <template>
         <v-card class="d-flex flex-wrap mh-500 grey lighten-5 " v-show="!systemDesign">
            <div class="midhint" v-if="!selectedSite">Please choose a site to view it's models</div>
            <div class="midhint" v-if="selectedSite && pointcloudsList.length < 1">This site is empty</div>
            <v-card
               v-for="(n, index) in pointcloudsList"
               :key="'__'+index">
               <v-col class="sitebox" :key="'siteBox'+index">
                  <v-card class="mx-auto rounded" light elevation="2" width="340" >
                     <v-img :src="n.file_thumb" height="20"></v-img>
                     <v-card-title style="cursor:pointer" @click="$store.dispatch('storeIframeUrl', n.id); selected_point_cloud_id=n.id; getPointCloudTagsList();">
                        {{n.id}} | {{n.title}}
                     </v-card-title>
                     <v-card-text>
                        <v-row align="center" class="mx-0">
                           <div>{{ n.short_desc }}</div>
                        </v-row>
                     </v-card-text>
                     <v-icon class="editbutton" @click="editPointcloud(n)">mdi-pencil</v-icon>
                     <v-icon class="editbutton2" @click="$store.dispatch('storeIframeUrl', n.id); selected_point_cloud_id=n.id; getPointCloudTagsList();">mdi-label</v-icon>

                     <v-switch class="pl-3" v-model="n.is_active" :label="`${togtitle(n, n.is_active)}`" @click="changeActiveStatus(n)"></v-switch>

                  </v-card>
               </v-col>
            </v-card>
         </v-card>
      </template>

      <svgEditor
         :svgItem="systemDesign"
         :focusTarget="focusSystemTagId"
         @close-svg-editor="closeSvgEditor"/>

      <PointCloudViewer  @gototag="gototag" />
   </div>
</template>
<script>
import System from '@/models/System.js';
import SiteService from '@/service/SiteService.js';
import SystemTagService from '@/service/SystemTagService.js';
import PointcloudTag from '@/models/PointcloudTag.js';
import util from '@/utils';
import PointcloudService from '@/service/PointcloudService.js';
import svgEditor from '@/components/svgEditor.vue';
import SiteSelect from '@/components/SiteSelect.vue';
import PointCloudViewer from '@/components/PointCloudViewr';

export default {
   components: {
      PointCloudViewer,
      svgEditor,
      SiteSelect,
   },
   watch: {
      selectedSite: function (site) {
         if (this.pointCloudTagItem) {
            this.pointCloudTagItem = null;
         }
         if (site) {
            this.callPointCloudServiceListBySite(site);
         } else if (this.iurl) {
            this.closePointCloud();
         }
      },
   },
   computed: {
      iurl() {
         return this.$store.state.app.iframeUrl;
      },
   },
   data() {
      return {
         createTagMod: false,
         show: false,
         total: 0,
         pointcloudsList: [],
         siteslist: [],
         pointCloudTagItem: null,
         selectedSite: null,
         systemDesign: null,
         selected_point_cloud_id: '',
         PointCloudTagsList: null,
         showhint: false,
         systemDesign: null,
         focusSystemTagId: null,
         showPointCloudTagInf: false,
         pointCloudTagItem: null,
         infPosition: { x: 200, y: 200 },
         showPointCloudForm: false,
      };
   },
   methods: {
       gototag(pointCloudTag) {
         console.log('gototag', pointCloudTag);
         // systemsList
         // const systemTag = this.systemsList.find((obj) => obj.id === tagid);
         this.editSvg(pointCloudTag.system_tag.system);
         this.$store.dispatch('storeIframeUrl', null);
      },
      showEditTagForm() {
         this.showPointCloudForm = true;
      },
      openInfoLayer() {
         this.showhint = true;
      },
      closePointCloud() {
         if (this.showhint) {
            this.showhint = false;
         } else {
            this.$store.dispatch('storeIframeUrl', null);
         }
      },

      closeSvgEditor() {
         this.systemDesign = null;
      },
      passClickTag(tagid) {
         console.log('this.PointCloudTagsList', this.PointCloudTagsList);
         const result = this.PointCloudTagsList.find((obj) => obj.id === tagid);
         this.showPointCloudTagInf = true;
         this.pointCloudTagItem = result;
      },
      clearPointCloudTagItem() {
         this.pointCloudTagItem = null;
      },
      getPointCloudTagsList() {
         return;
         if (this.PointCloudTagsList)
         {
            this.clearAllTagsOnCloud();
         }
         
         const pointCloudTagsService = new PointCloudTagsService();
         if (!this.selected_point_cloud_id) {
            console.log('Error, cant find point cloud id');
            return;
         }
         pointCloudTagsService.list(this.selected_point_cloud_id).then((res) => {
            if (res) {
               this.PointCloudTagsList = res.data;
               this.PointCloudTagsList.forEach((element) => {
                  this.drawTagOnCloud(element);
               });
            }
         });
      },
      clearAllTagsOnCloud() {
         window.potreeFrame.postMessage({ type: 'deleteAllTags' }, '*');
      },
      drawTagOnCloud(element) {
         window.potreeFrame.postMessage({ type: 'drawTag', data: element }, '*');
      },
      putTagOnCloud() {
         window.potreeFrame.postMessage({ type: 'putTag' }, '*');
         this.createTagMod = true;
      },
      closeSvgEditor() {
         this.systemDesign = null;
      },
      updateData(data) {
         this.pointCloudTagItem = data;
         this.callPointCloudServiceListBySite(this.selectedSite);
      },
      editPointcloud(sitedata) {
         this.pointCloudTagItem = sitedata;
      },
      editSvg(n) {
         this.systemDesign = n;
      },
      createItem() {
         util.showMessage('not functional', 'error');
         // if (!this.selectedSite) {
         //    util.showMessage('Please choose a site first', 'error');
         //    return;
         // }
         // this.pointCloudTagItem = new System();
         // this.pointCloudTagItem.site_id = this.selectedSite.id;
      },
      togtitle(n, stat) {
         if (stat) {
            return 'active';
         }
         return 'inactive';
      },
      changeActiveStatus(n) {
         const pointcloudService = new PointcloudService(this);
         pointcloudService.update(n.id, n);
      },
      callSiteServiceList(page = 1) {
         this.page = page;
         this.loading = true;
         const siteService = new SiteService(this);
         siteService.list(page, this.sort, this.order).then((list) => {
            if (list) {
               this.siteslist = list.data.data;
            }
            this.loading = false;
         });
      },
      callPointCloudServiceListBySite(site) {
         if (!site) {
            site = this.selectedSite;
         }
         this.loading = true;
         const pointcloudService = new PointcloudService(this);
         pointcloudService.list(site.id).then((list) => {
            console.log('index.vue: src/views/pointcloud/index.vue: callPointCloudServiceListBySite', list);
            if (list) {
               this.pointcloudsList = list.data;
            }
            this.loading = false;
         });
      },
      passDataToTagForm(data) {
         this.pointCloudTagItem = new PointcloudTag({
            id: null,
            site_id: this.selectedSite.id,
            point_cloud_id: this.selected_point_cloud_id,
            icon_pos: data.icon_pos,
            cam_pos: data.cam_pos,
         });
         this.showPointCloudForm = true;
      },
   },
   created() {
      this.callSiteServiceList();
      window.addEventListener('message', (event) => {
         if (typeof (event.data.type) === 'string') {
            // TODO: #3 need to check event.origin for security
            console.log(event.data.type);
            switch (event.data.type) {
            case 'createTag':
               this.passDataToTagForm(event.data.data);
               this.createTagMod = false;
               break;
            case 'clickTag':
               this.passClickTag(event.data.data.id);
               this.createTagMod = false;
               break;
            case 'ready':
               this.getPointCloudTagsList();
               break;
            default:
               break;
            }
         }
      });
   },
   mounted() {
   },
};
</script>
<style scoped>
.editbutton {
   position: absolute;
   top: 8px;
   color: #267eea;
   right: 15px;
}

.editbutton2 {
   position: absolute;
   top: 8px;
   color: #267eea;
   right: 45px;
}

</style>
